@font-face {
	font-family: 'System Mono';
	src: url('./assets/FTSystemMono-Regular.woff2') format('woff2');
	font-style: normal;
}

@font-face {
	font-family: 'Helvetica';
	src: url('./assets/HelveticaNeue-Medium.woff2') format('woff2');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Source Serif 4';
	src: url('./assets/source-serif-4-v7-latin-300.woff2') format('woff2');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Source Serif 4';
	src: url('./assets/source-serif-4-v7-latin-300italic.woff2') format('woff2');
	font-weight: 300;
	font-style: italic;
}
